/*
Mobile main menu
*/
let mobileMenu = document.getElementById("mobileMenu");
let menuTrigger = document.getElementById("menuTrigger");
let hamburger = document.getElementById("hamburger");
let menuClose = document.getElementById("menuClose");
let mobileSubmenu = document.getElementById("mobileSubmenu");

if (menuTrigger) {
    menuTrigger.addEventListener("click", function () {
        if (hamburger) {
            hamburger.classList.toggle("block");
            hamburger.classList.toggle("hidden");
        }

        if (menuClose) {
            menuClose.classList.toggle("hidden");
            menuClose.classList.toggle("block");
        }

        if (mobileSubmenu) {
            mobileSubmenu.classList.toggle("hidden");
            mobileSubmenu.classList.toggle("block");
        }
    });
}

/*
  RCA Info and FAQ
*/
let labels = document.querySelectorAll(".selector-type label");

// - Get target divs
let rca = document.getElementById("rca-block");
let faq = document.getElementById("faq-block");

// - Default state
if (rca && faq) {
    rca.style.display = "block";
    faq.style.display = "none";
}

// - Add click event listener to each label
labels.forEach((label, index) => {
    label.addEventListener("click", function () {
        // Remove 'selectat' class from all labels
        labels.forEach((label) => {
            label.classList.remove("selected");
        });

        // Add 'selectat' class to the clicked label
        this.classList.add("selected");

        // Show/hide divs based on the label clicked
        if (index === 0) {
            rca.style.display = "block";
            faq.style.display = "none";
        } else if (index === 1) {
            rca.style.display = "none";
            faq.style.display = "block";
        }
    });
});

/*
  About insurances in homepage
*/
let aboutInsurances = document.querySelectorAll(".about-insurances label");

// - Get target divs
let asigurare_rca = document.getElementById("asigurare-rca");
let asigurare_casco = document.getElementById("asigurare-casco");
let asigurare_locuinta = document.getElementById("asigurare-locuinta");
let asigurare_calatorie = document.getElementById("asigurare-calatorie");

// - Default state
if (asigurare_rca) {
    asigurare_rca.style.display = "grid";
    asigurare_casco.style.display = "none";
    asigurare_locuinta.style.display = "none";
    asigurare_calatorie.style.display = "none";
}

// - Add click event listener to each label
if (aboutInsurances.length > 0) {
    aboutInsurances.forEach((label, index) => {
        label.addEventListener("click", function () {
            // Remove 'selectat' class from all labels
            aboutInsurances.forEach((label) => {
                label.classList.remove("selected");
            });

            // Add 'selectat' class to the clicked label
            this.classList.add("selected");

            // Show/hide divs based on the label clicked
            if (index === 0) {
                asigurare_rca.style.display = "grid";
                asigurare_casco.style.display = "none";
                asigurare_locuinta.style.display = "none";
                asigurare_calatorie.style.display = "none";
            } else if (index === 1) {
                asigurare_rca.style.display = "none";
                asigurare_casco.style.display = "grid";
                asigurare_locuinta.style.display = "none";
                asigurare_calatorie.style.display = "none";
            } else if (index === 2) {
                asigurare_rca.style.display = "none";
                asigurare_casco.style.display = "none";
                asigurare_locuinta.style.display = "grid";
                asigurare_calatorie.style.display = "none";
            } else if (index === 3) {
                asigurare_rca.style.display = "none";
                asigurare_casco.style.display = "none";
                asigurare_locuinta.style.display = "none";
                asigurare_calatorie.style.display = "grid";
            }
        });
    });
}

/*
  Homepage fara graba popup
*/
let faraGrabaPopup = document.getElementById("fara-graba-popup");
let closeFaraGrabaPopup = document.getElementById("close-fara-graba-popup");
let faraGrabaVideo = document.querySelector(".fara-graba-video");
let faraGrabaTitle = document.querySelector(".fara-graba-title");
let noRush = document.querySelectorAll(".no-rush a");

if (faraGrabaPopup) {
    faraGrabaPopup.style.display = "none";
}

if (noRush) {
    noRush.forEach((button) => {
        button.addEventListener("click", function (event) {
            event.preventDefault();
            const youtubeId = button.dataset.youtube;
            const title = button.dataset.title;
            faraGrabaTitle.textContent = title;
            faraGrabaVideo.src = `https://www.youtube.com/embed/${youtubeId}`;
            faraGrabaPopup.style.display = "flex";
        });
    });
}

if (closeFaraGrabaPopup) {
    closeFaraGrabaPopup.addEventListener("click", function () {
        faraGrabaVideo.src = "";
        faraGrabaPopup.style.display = "none";
    });
}

/*
  Feedback popup
*/
let feedbackPopup = document.getElementById("feedback-popup");
let feedbackTrigger = document.getElementById("feedback-trigger");
let feedbackTriggerMobile = document.getElementById("feedback-trigger-mobile");
let closefeedbackPopup = document.getElementById("close-feedback-popup");

if (feedbackPopup) {
    feedbackPopup.style.display = "none";
}

if (feedbackTrigger) {
    feedbackTrigger.addEventListener("click", function (event) {
        event.preventDefault();
        feedbackPopup.style.display = "flex";
    });
}

if (feedbackTriggerMobile) {
    feedbackTriggerMobile.addEventListener("click", function (event) {
        event.preventDefault();
        feedbackPopup.style.display = "flex";
    });
}

if (closefeedbackPopup) {
    closefeedbackPopup.addEventListener("click", function () {
        feedbackPopup.style.display = "none";
    });
}

/*
  Reset password popup
*/
let resetPasswordPopup = document.getElementById("reset-password-popup");
let closeResetPasswordPopup = document.getElementById("close-reset-password-popup");

if (closeResetPasswordPopup) {
    closeResetPasswordPopup.addEventListener("click", function () {
        resetPasswordPopup.classList.add("!hidden");
    });
}

/*
  Matriculation RCA Landing
*/
let toMatriculation = document.getElementById("to-matriculation");
let licensePlateWrapper = document.getElementById("license-plate-wrapper");
let licensePlate = document.getElementById("license_plate");

if (toMatriculation) {
    toMatriculation.addEventListener("change", function () {
        if (this.checked) {
            if (licensePlateWrapper)
                licensePlateWrapper.classList.add("disabled-plate");
            if (licensePlate) {
                licensePlate.setAttribute("disabled", true);
                licensePlate.value = "";
            }
        } else {
            if (licensePlateWrapper)
                licensePlateWrapper.classList.remove("disabled-plate");
            if (licensePlate) licensePlate.removeAttribute("disabled");
        }
    });
}

// Automotive logos filter
const cautati = document.getElementById("cautati");
const logos = document.querySelectorAll(".automotive-logos a");

if (cautati) {
    cautati.focus();
    cautati.addEventListener("input", function () {
        const value = this.value.toLowerCase();
        logos.forEach((logo) => {
            const text = logo.querySelector("p").textContent.toLowerCase();
            if (text.includes(value)) {
                logo.style.display = "block";
            } else {
                logo.style.display = "none";
            }
        });
        // count logos that are visible
        const visibleLogos = document.querySelectorAll(
            '.automotive-logos a[style="display: block;"]'
        );
        if (visibleLogos.length === 0) {
            document.getElementById("no-results").style.display = "block";
        } else {
            document.getElementById("no-results").style.display = "none";
        }
    });
}

// RCA regional filter
const cautatiRegional = document.getElementById("cautati-regional");
const regions = document.querySelectorAll(".rca-regional a");

if (cautatiRegional) {
    cautatiRegional.focus();
    cautatiRegional.addEventListener("input", function () {
        const value = this.value.toLowerCase();
        regions.forEach((region) => {
            const text = region.textContent.toLowerCase();
            if (text.includes(value)) {
                region.style.display = "block";
            } else {
                region.style.display = "none";
            }
        });
        // count logos that are visible
        const visibleRegions = document.querySelectorAll(
            '.rca-regional a[style="display: block;"]'
        );
        if (visibleRegions.length === 0) {
            document.getElementById("no-results-regional").style.display =
                "block";
        } else {
            document.getElementById("no-results-regional").style.display =
                "none";
        }
    });
}

// Rating stars
const outoff = document.getElementById("outoff");
const rating = document.querySelectorAll('input[name="rating"]');

if (rating) {
    rating.forEach((star) => {
        star.addEventListener("click", (e) => {
            outoff.textContent = e.target.value;
        });
    });
}

const forgotButton = document.getElementById("forgot-form-submit");

if (forgotButton) {
    async function onSubmitForgot() {
        let form = document.getElementById('password-forgot-form'),
            captcha = document.getElementById('recaptcha-token');

        await grecaptcha.ready(async function() {
            let token = await grecaptcha.execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY);
            captcha.value = token;
            form.submit();
        });

    }

    forgotButton.addEventListener("click", (event) => {
        event.preventDefault();
        onSubmitForgot();
    });
}

const registerButton = document.getElementById("register-form-submit");

if (registerButton) {
    async function onSubmitRegister() {
        let form = document.getElementById('register-form'),
            captcha = document.getElementById('recaptcha-token');

        await grecaptcha.ready(async function() {
            let token = await grecaptcha.execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY);
            captcha.value = token;
            form.submit();
        });

    }

    registerButton.addEventListener("click", (event) => {
        event.preventDefault();
        onSubmitRegister();
    });
}

            